<template>
  <Fragment>
    <div class="selectplanpopup">
      <div class="buys_center">
        <div class="selectplan">
          <div class="buys_head">
            <div class="buys_close">
              <i class="atuicons atuicons-close"></i>
            </div>
          </div>
          <div class="selectplan_content">
            <div
              v-if="plans.length"
              v-swiper:mySwiper="swiperOption"
              id="selectPlanSlider"
            >
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="plan in plans" :key="plan.id">
                  <a
                    class="preorder_plan"
                    data-plan-value="$30"
                    href="#"
                    @click.prevent="selectPlan(plan)"
                  >
                    <div class="preorder_plan_title">
                      {{ getMonthTitle(plan) }}
                    </div>
                    <div class="preorder_plan_name" v-html="plan.title"></div>
                    <div class="preorder_plan_cost">
                      ${{ plan.price }}<span>per month</span>
                    </div>
                    <div class="preorder_plan_tip">
                      <div class="preorder_plan_tip_title">
                        <span>†</span> MEMBERSHIP DETAILS:
                      </div>
                      <div
                        class="preorder_plan_tip_text"
                        v-html="plan.description"
                      ></div>
                    </div>
                    <div class="preorder_plan_select">SELECT FITNESS PLAN</div>
                  </a>
                </div>
              </div>
              <div class="swiper-pagination" id="selectPlansPagination"></div>
            </div>
            <p style="text-align: center" v-if="plans.length < 1">
              Please wait...
            </p>
            <div v-else class="selectplan_button">
              <div class="button ClosePopup">skip the payment</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="buysubscription">
      <div class="buys_center">
        <div class="buys">
          <div class="buys_head">
            <div class="buys_close">
              <i class="atuicons atuicons-close"></i>
            </div>
          </div>
          <div class="buys_bg"></div>
          <div class="buys_content">
            <div class="buys_title">Keep getting<br />new workouts here</div>
            <div class="buys_text">
              <label class="buys_checkbox"
                ><input type="checkbox" /><span
                  >You Agree To The <a href="#">Privacy Policy </a>and
                  <a href="#">User Agreement</a></span
                ></label
              >
              <div class="buys_button">
                <a class="button" href="#">Renew subscription</a>
              </div>
              <p>
                Lie on an exercise ball with your abdomen on the center of the
                ball. Press the balls of your feet into the ground and keep both
                hands on the ground to stay balanced to begin. Then extend your
                right arm forward as you lift your left leg straight back and
                breathe out.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="confirmsubscription">
      <div class="confirm_center">
        <div class="confirm">
          <div class="confirm_head">
            <div class="buys_close">
              <i class="atuicons atuicons-close"></i>
            </div>
          </div>
          <div v-if="selectedPlan" class="confirm_content">
            <div class="confirm_title">
              {{ getMonthTitle(selectedPlan) }}
            </div>
            <div class="confirm_description" v-html="selectedPlan.title"></div>
            <div class="confirm_cost">${{ selectedPlan.price }}</div>
            <div class="confirm_period">per month</div>
            <div class="confirm_pre">
              Requires a pre-payment of <span>${{ selectedPlan.total }}</span>
            </div>
            <div class="confirm_text">
              <p v-html="selectedPlan.description"></p>
            </div>
            <div class="confirm_buttons">
              <a class="ConfirmPlanBuy button button_red" href="#"
                >Confirm your plan</a
              ><a class="CancelPlan button button_black">Cancel</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="typepayment">
      <div class="buys_center">
        <div class="buys">
          <div class="buys_head">
            <div class="buys_close">
              <i class="atuicons atuicons-close"></i>
            </div>
          </div>
          <div class="buys_content">
            <div class="typepayment_title">
              {{
                isCreatingSubscription
                  ? "Please Wait...Redirecting on PayPal..."
                  : "SELECT A PAYMENT METHOD"
              }}
            </div>
            <div v-if="!isCreatingSubscription" class="payment_types">
              <div class="payment_type">
                <a @click.prevent="paypal">
                  <div class="payment_t">PayPal</div>
                  <div class="payment_logo">
                    <img :src="require(`@/assets/img/paypallogo.png`)" />
                  </div>
                </a>
             
              </div>
              <p>For other payment methods, please contact us at <a href="mailto:info@atumobile.com">info@atumobile.com</a></p>
              <div class="payment_type_or" v-show="false">OR</div>
              <div class="payment_type" v-show="false">
                <a class="CreditCard" href="#.html">
                  <div class="payment_t">Credit Card</div>
                  <div class="payment_logo">
                    <div class="payment_logos">
                      <img :src="require(`@/assets/img/visa.png`)" />
                      <img :src="require(`@/assets/img/mastercard.png`)" />
                      <img :src="require(`@/assets/img/aecard.svg`)" />
                      <img :src="require(`@/assets/img/applepay.png`)" />
                      <img :src="require(`@/assets/img/discover.png`)" />
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="creditcardpopup">
      <div class="confirm_center">
        <div class="confirm">
          <div class="confirm_head">
            <div class="buys_close">
              <i class="atuicons atuicons-close"></i>
            </div>
          </div>
          <div class="creditcard_content">
            <div class="creditcard_head">
              <div class="creditcard_head_t">
                <span>Credit Card</span><i></i>
              </div>
              <div class="creditcard_images">
                <img :src="require(`@/assets/img/visa.png`)" />
                <img :src="require(`@/assets/img/mastercard.png`)" />
                <img :src="require(`@/assets/img/aecard.svg`)" />
                <img :src="require(`@/assets/img/applepay.png`)" />
                <img :src="require(`@/assets/img/discover.png`)" />
              </div>
            </div>
            <form class="creditcard_form" @submit.prevent="elavon">
              <div class="creditcard_group">
                <div class="title_small">Billing Details</div>
                <div class="creditcard_inputs">
                  <div class="settings__input input50">
                    <label class="settings_input_title required"
                      >Card Number</label
                    ><input
                      class="settings_input"
                      type="text"
                      required
                      id="cardnumber"
                      v-mask="'#### #### #### ####'"
                      placeholder="XXXX XXXX XXXX XXXX"
                      v-model="form.card_number"
                    />
                  </div>
                  <div class="settings__input input25">
                    <label class="settings_input_title required"
                      >Expiration Date</label
                    ><input
                      class="settings_input"
                      type="text"
                      required
                      id="carddate"
                      v-mask="'##/##'"
                      placeholder="MM/YY"
                      v-model="form.card_expiry_date"
                    />
                  </div>
                  <div class="settings__input input25">
                    <label class="settings_input_title required">CVV</label
                    ><input
                      class="settings_input"
                      type="text"
                      required
                      id="cardcvv"
                      v-mask="'###'"
                      placeholder="XXX"
                      v-model="form.card_cvv"
                    />
                  </div>
                  <div class="settings__input input100">
                    <label class="settings_input_title required"
                      >Name On Card</label
                    ><input
                      class="settings_input"
                      type="text"
                      v-model="form.card_holder_name"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="creditcard_group">
                <div class="title_small">Contact Details</div>
                <div class="creditcard_inputs">
                  <div class="settings__input input100">
                    <label class="settings_input_title required"
                      >Street Address</label
                    ><input
                      class="settings_input"
                      type="text"
                      required
                      v-model="form.address"
                    />
                  </div>

                  <div class="settings__input input50">
                    <label class="settings_input_title required">Zip Code</label
                    ><input
                      class="settings_input"
                      type="text"
                      required
                      v-model="form.postal_code"
                    />
                  </div>
                </div>
              </div>
              <div class="creditcard_buttons">
                <button class="button button_red">
                  {{ isCreatingSubscription ? "Please wait..." : "Pay Now" }}
                </button>
                <a class="CancelPay button button_black" href="#">Cancel</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
</template>

<script>
import { createSubscription } from "@/api/subscription";
import swal from "sweetalert";

import { Fragment } from "vue-fragment";
import { fetchPlans } from "@/api/plan";
import NProgress from "nprogress";

import SwiperClass, { Pagination } from "swiper";
SwiperClass.use([Pagination]);

import { directive } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";

export default {
  name: "PaymentPopup",
  props: { buyClickCount: Number },
  components: { Fragment },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      plans: [],
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        loop: false,
        breakpoints: {
          320: {
            slidesPerView: "auto",
            spaceBetween: 10,
          },
          360: {
            slidesPerView: "auto",
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        },
      },
      selectedPlan: null,
      isCreatingSubscription: false,
      form: {
        card_number: "",
        card_expiry_date: "",
        card_cvv: "",
        card_holder_name: "",
        postal_code: "",
        address: "",
        plan_id: "",
      },
    };
  },
  methods: {
    selectPlan(plan) {
      this.selectedPlan = plan;
    },
    getMonthTitle(plan) {
      let duration = parseInt(plan.duration);
      if (duration === 1) {
        return "30 DAY PLAN";
      }

      return `${duration} MONTH PLAN`;
    },
    paypal() {
      this.isCreatingSubscription = true;
      NProgress.start();
      createSubscription({ planId: this.selectedPlan.id })
        .then(({ data }) => {
          window.location = data.url;
        })
        .catch(() => {
          console.log("error while creating paypal subscription");
          this.isCreatingSubscription = false;
          NProgress.done();
        });
    },
    elavon() {
      NProgress.start();
      this.isCreatingSubscription = true;
      this.form.plan_id = this.selectedPlan.id;
      this.$store
        .dispatch("user/createRecurringPayment", this.form)
        .then((response) => {
          document.querySelector(".buys_close").click();
          swal(
            "Subscription Activated!",
            response.message || "Your subscription has been activated!",
            "success"
          );
          this.$router.push({ name: "Today" }).catch((error) => {
            console.log(
              "router push error in elavon payment success handler",
              error
            );
          });
        })
        .catch(() => {
          console.log("error while creating recurring payment");
        })
        .finally(() => {
          NProgress.done();
          this.isCreatingSubscription = false;
        });
    },
    fetchPlans() {
      NProgress.start();
      fetchPlans()
        .then(({ data }) => {
          this.plans = data;
        })
        .catch(() => {
          console.log("error while fetching plans");
        })
        .finally(() => {
          NProgress.done();
        });
    },
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
  },
  created() {
    this.fetchPlans();
  },
  watch: {
    buyClickCount: function (newValue) {
      if (newValue > 0) {
        document.querySelector(".selectplanpopup").style.display = "block";
        document.querySelector("body").classList.add("modalopen");
        if (this.mySwiper) {
          this.mySwiper.update();
        }
      }
    },
  },
};
</script>
